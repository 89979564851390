export const requiredPermission = {
    SUPERADMIN_FULL:'SUPERADMIN_FULL',
    ENTITIES_VIEW: 'ENTITIES_VIEW',
    ENTITIES_EDIT: 'ENTITIES_EDIT',
    USERS_FULL: 'USERS_FULL',
    USERS_VIEW: 'USERS_VIEW',
    USERS_CREATE: 'USERS_CREATE',
    USERS_EDIT: 'USERS_EDIT',
    USERS_DELETE: 'USERS_DELETE',
    ACCOUNTS_FULL: 'ACCOUNTS_FULL',
    ACCOUNTS_VIEW: 'ACCOUNTS_VIEW',
    ACCOUNTS_RELOAD: 'ACCOUNTS_RELOAD',
    ACCOUNTS_EDIT: 'ACCOUNTS_EDIT',
    ACCOUNTS_DELETE: 'ACCOUNTS_DELETE',
    REQUESTS_FULL: 'REQUESTS_FULL',
    REQUESTS_VIEW: 'REQUESTS_VIEW',
    REQUESTS_CREATE: 'REQUESTS_CREATE',
    REQUESTS_UPDATE: 'REQUESTS_UPDATE',
    REQUESTS_DELETE: 'REQUESTS_DELETE',
    CARDS_FULL: 'CARDS_FULL',
    CARDS_VIEW: 'CARDS_VIEW',
    CARDS_UPDATE: 'CARDS_UPDATE',
    TRANSACTIONS_FULL: 'TRANSACTIONS_FULL',
    TRANSACTIONS_EXPORT: 'TRANSACTIONS_EXPORT',
    TRACES_ALL: 'TRACES_ALL',
    TRACES_EXPORT: 'TRACES_EXPORT'
}