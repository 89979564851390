import { Route, Routes } from 'react-router-dom';
import NotFoundpage from '../pages/NotFoundPage';
import 'react-toastify/dist/ReactToastify.css';
import AppContainer from '../containers/AppContainer';
import { lazy, useEffect } from 'react';
import useServerMain from '../api/useServer';
import Loading from '../components/misc/Loading';
import { usePermissions } from '../hooks/usePermissions';
import { requiredPermission } from '../utils/requiredPermission';

const AppRoute = () => {
	const { initSystem, isLoading } = useServerMain();
	const { hasPermission } = usePermissions();
	useEffect(() => {
		initSystem();
	}, []);

	const LazyDashboard = lazy(() => import('../pages/DashboardPage'));
	const LazyAccounts = lazy(() => import('../containers/accounts/Accounts'));
	const LazyCard = lazy(() => import('../containers/card/Cards'));
	const LazyCardRequests = lazy(
		() => import('../containers/request/CardRequests'),
	);
	const LazyEntity = lazy(() => import('../containers/entity/Entity'));

	const LazyEntityTypes = lazy(
		() => import('../containers/entityTypes/EntityTypes'),
	);

	const LazyRoles = lazy(() => import('../containers/users/Roles'));

	const LazyAccountDetails = lazy(
		() => import('../containers/accounts/AccountDetails'),
	);
	const LazyUsers = lazy(() => import('../containers/users/Users'));
	const LazyTransaccions = lazy(
		() => import('../containers/analitics/Transaccions'),
	);
	const LazyTraces = lazy(() => import('../containers/analitics/Traces'));
	const LazyUserOfCategorys = lazy(
		() => import('../containers/analitics/UserOfCategorys'),
	);

	if (isLoading) {
		return (
			<div className='flex w-full h-full justify-center items-center'>
				<Loading />
			</div>
		);
	}

	return (
		<Routes>
			<Route path='/' element={<AppContainer />}>
				<Route index Component={LazyDashboard} />
				{hasPermission(requiredPermission.ENTITIES_VIEW) && (
					<Route path='/entities' Component={LazyEntity} />
				)}
				{hasPermission(requiredPermission.ACCOUNTS_VIEW) && (
					<>
						<Route path='/accounts' Component={LazyAccounts} />
						<Route path='/accounts/:accountId' Component={LazyAccountDetails} />
					</>
				)}
				{hasPermission(requiredPermission.CARDS_VIEW) && (
					<Route path='/cards/' Component={LazyCard} />
				)}
				{hasPermission(requiredPermission.REQUESTS_VIEW) && (
					<Route path='/requests' Component={LazyCardRequests} />
				)}
				{hasPermission(requiredPermission.USERS_VIEW) && (
					<Route path='/users' Component={LazyUsers} />
				)}
				{hasPermission(requiredPermission.SUPERADMIN_FULL) && (
					<>
						<Route path='/config/roles' Component={LazyRoles} />
						<Route path='/config/entity' Component={LazyEntityTypes} />
					</>
				)}

				<Route path='/analitics/transaccions' Component={LazyTransaccions} />
				<Route path='/analitics/traces' Component={LazyTraces} />
				<Route
					path='/analitics/userOfCategorys'
					Component={LazyUserOfCategorys}
				/>
			</Route>
			<Route path='/*' element={<NotFoundpage />} />
		</Routes>
	);
};

export default AppRoute;
