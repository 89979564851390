import { useAppSelector } from '../store/hooks';

export const usePermissions = () => {
 const { user, allowedPermissions } = useAppSelector((state) => state.init);

 const hasPermission = (requiredPermission: any) => {
     if (user?.isSuperAdmin) {
        return true
    }
    if (allowedPermissions.includes(requiredPermission)) {
        return true;
     }
    
     // Si no tiene el permiso específico, verifica si tiene un permiso 'FULL' para la misma entidad -----
     const entity = requiredPermission.split('_')[0]; 
     const fullPermission:any = `${entity}_FULL`;
     if (allowedPermissions.includes(fullPermission)) {
        return true;
     }
    // ---------------------------------------------------------------------------------------------------
  
    return false;
    };

 return { hasPermission };
};